<template>
 <div>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient45" x1="0.786" x2="0.5" y2="1">
     <stop offset="0" stop-color="#ffb3a5"/>
     <stop offset="1" stop-color="#fc7d6c"/>
    </linearGradient>
   </defs>
   <g id="Patreon" transform="translate(-450 -448)">
    <rect :fill="background" data-name="Rectangle 21" height="60" id="Rectangle_21" transform="translate(450 448)" width="60"/>
    <path d="M29.2,11.248A10.5,10.5,0,1,1,18.716.738,10.493,10.493,0,0,1,29.2,11.248ZM0,28.738H5.132v-28H0Z" data-name="Icon awesome-patreon" id="Icon_awesome-patreon" style="fill: url(#linear-gradient45);" transform="translate(465 463.262)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>